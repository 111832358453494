@import '@codametrix/ui-common/src/styles/_variables';

.provider-details {
  .external-provider {
    background-color: $warning-300 !important;
  }
  .employer-status {
    background-color: $structural-300;
  }

  .billing-icon {
    path {
      fill: $success-500;
    }
  }
  .no-billing-icon {
    path {
      fill: $warning-500;
    }
  }
  .empty-message {
    display: block;
    width: 100%;
  }

  .vertical-info {
    display: flex;
    flex-direction: column;
    gap: $spacing-2x;
    height: $spacing-10x;
  }

  .info-header {
    @include type-overline-1;
    color: $text-medium-emphasis;
  }

  .info-cell {
    @include type-body-2;
    margin: 0px;
    color: $text-high-emphasis;
    vertical-align: text-top;
  }

  .flex-box {
    display: flex;
    gap: $spacing-5x;
  }

  &.provider-header-section {
    border-bottom: 1px solid $tundra-200;
    padding-bottom: $spacing-2x;
    display: flex;
    gap: $spacing-5x;

    .provider-name {
      @include type-heading-h4;
      padding-bottom: $spacing-5x;
    }

    .employee-info {
      display: flex;
      gap: $spacing-5x;
    }
  }

  &.provider-body-section {
    .provider-location {
      @include type-subtitle-1;
    }

    .label {
      background-color: $structural-300;
    }

    .specialty-row {
      padding: $spacing-2x;

      .flex-box {
        gap: $spacing-4x;
      }
    }

    .provider-specialties,
    .employer-status {
      .info-cell {
        display: flex;
        gap: $spacing-2x;
        overflow: visible;
        flex-wrap: wrap;
      }
    }
  }
}
