@import './layout';
@import '@codametrix/ui-common/src/styles/_variables';
@import './core-components';

body,
html {
  font-size: 14px !important;
  margin: 0;
  padding: 0;
}

.App-link {
  color: $linkColor;
}

.empty-message {
  padding: 8px;
  text-align: center;
  border: 1px solid #f3f3f3;
  color: darkgray;
  font-weight: 400;
}

.content-container {
  background-color: $mainBgColor;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 8px 0.7rem 0.7rem 0.7rem;
}

.content-vacant {
  text-align: center;
  padding: 4em 0;
  color: $darkInfoTextColor;
}

.universal-feedback {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
  z-index: 401;
}

@media (min-width: 1540px) {
  .universal-feedback {
    left: 775px;
  }
}

.dont-wrap {
  display: inline-block;
}

.ellipsify {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cmx-application-chakra {
  .btn.btn,
  .icon-button .icon-image-container {
    @include secondary-button-base;
    background-color: transparent;
    color: $sahara-100 !important;
    border: 1px solid $sahara-100;

    &:hover {
      color: $sahara-100;
      border: 1px solid $sahara-100;
    }

    &.active {
      color: $button-primary-text-color !important;
      background-color: $sahara-100;
    }
  }

  .icon-button {
    .icon-image {
      color: $sahara-100;
    }

    &:hover .icon-image {
      @include primary-button-base;
      color: $sahara-200;
    }
  }
  .dropdown {
    .menu {
      left: unset;
      right: 0;
    }
  }
}
