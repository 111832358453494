//cmx brand set
$sahara-50: #fedfd7;
$sahara-100: #e5927d;
$sahara-200: #c37c6a;
$sahara-300: #84564a;
$tundra-100: #d1f3ff;
$tundra-200: #a9d5e5;
$tundra-300: #8cb4c2;
$tundra-350: #81a3af;
$tundra-400: #6b8d99;
$tundra-500: #283b42;
$polar: #ffffff;
$mirage: #000000;

//ui primary
$primary-50: $sahara-50;
$primary-100: $sahara-100;
$primary-200: $sahara-200;
$primary-300: $sahara-300;

//text
$text-high-emphasis: #203d48;
$text-medium-emphasis: #717f96;
$text-low-emphasis: #b0ccd6;
$text-light: $polar;
$text-interactive: $sahara-200;

//greys - these are in the styleguide and renamed tundra above

//stroke
$stroke-100: $tundra-100;
$stroke-200: $tundra-200;
$stroke-300: $tundra-400;
$stroke-400: $tundra-500;

//alerts & warnings
$success-300: #ccffda;
$success-500: #42a45c;
$warning-300: #ffe9cc;
$warning-500: #f5a73f;
$alert-300: #ffcccc;
$alert-500: #e50000;
$progress-300: #e0e8ff;
$progress-500: #4169e1;

//structural
$structural-0: $polar;
$structural-50: #fcfeff;
$structural-100: #f7fdff;
$structural-200: #f0fbff;
$structural-300: #f2f8fa;
$structural-400: #f6fdff;

//tags & chips
$chips-grey-50: #f0f2f4;
$chips-grey-500: #45556e;
$chips-brown-50: #feeeea;
$chips-brown-500: #c37c6a;
$chips-green-50: #e0fcf0;
$chips-green-500: #26835b;
$chips-yellow-50: #fff7d8;
$chips-yellow-500: #e1b129;
$chips-red-50: #ffedec;
$chips-red-500: #c83729;
$chips-blue-50: #eaf2ff;
$chips-blue-500: #186ae1;
$chips-teal-50: #edf4f7;
$chips-teal-500: #45626e;
$chips-plum-50: #f3eaf2;
$chips-plum-500: #875882;

$cyan_100: $text-medium-emphasis;

//other
$loading-background-color: $structural-200;
$scrim-color: $tundra-500;
$scrim-color-light: $polar;

$taiga_500: #346ea3;
$taiga_50: #70ace4;
$savana_500: #f6844e;
$savana_50: #feb794;
$rainforest_500: #5ba37a;
$rainforest_50: #8be0af;
$prairie_500: #f1c54b;
$prairie_50: #e8d5a1;
$marine_500: #688d99;
$marine_50: #a9d5e5;
$coralreef_500: #f24646;
$coralreef_50: #ff9797;
$permafrost_500: #867775;
$permafrost_50: #bab5b4;
$bloom_500: #e15a6a;
$bloom_50: #ffb9c6;
$tropical_500: #bb529e;
$tropical_50: #f3a1dd;
$sand_500: #b97350;
$sand_50: #f0c5af;
