@import '@codametrix/ui-common/src/styles/_variables';

.exterior-container {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.exterior-header {
  background-color: $tundra-500;
  padding: 26px 30px;
  .logo {
    width: 16rem;
  }
}

.context-choice-form,
.service-line-choice-form {
  margin: 0 auto;

  .choose-inline-submit {
    margin-top: -5px;
  }

  .cmx-form.cmx-inline-form select {
    padding: 0.1rem;
    margin-top: 1px;
  }
}

@media only screen and (max-width: 600px) {
  .exterior-body {
    background-image: none;
  }
}

@media only screen and (min-width: 600px) {
  .exterior-body {
    background-color: $polar;
    background: url('../../assets/backgrounds/two-thinkers.jpg') no-repeat
      center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media only screen and (min-width: 1800px) {
  .exterior-body {
    background-size: cover;
  }
}

.exterior-body {
  background-repeat: no-repeat;
  flex: 1;

  .action-container {
    width: 26rem;
    margin-top: 5rem;
    padding: $standardPad;
    border-radius: 3px;
    position: absolute;
    right: 10rem;
  }

  .action {
    margin-bottom: 20px;
    background-color: $polar;
    padding: 10px;
    border-radius: 3px;
  }
  .form-input {
    font-size: $largeText;
    height: 2.4rem;
  }

  .form-row {
    padding: $standardPad;

    input:focus {
      border-color: $buttonColor;
      box-shadow: 0 0 0 0.1rem rgba(87, 85, 217, 0.2);
    }
  }

  .cmx-select-list {
    margin-left: $standardRelativePad;
    width: 22rem;
    height: 2.4rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}

.required-indicator {
  color: $alertTextColor;
  padding-left: $textPad;
}

.error-text {
  color: $errorTextColor;
  font-weight: $accentedWeight;
}

.warning-message {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 11px;
  padding-bottom: 11px;
  background-color: $warningBackgroundColor;
  border-radius: 3px;
  font-size: $smallText;

  .imo-message {
    display: block;
    margin-top: 1rem;
  }
}

.warning-message-container {
  margin-bottom: 5px;
}
