@import '@codametrix/ui-common/src/styles/_variables';

.button-toggle {
  @include chip-color-teal;
  @include type-overline-2;
  padding: $spacing-base $spacing-2x;
  border-radius: $radius-base;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: $spacing-8x;
  padding: $spacing-2d;
  min-width: $spacing-8x;

  .button-toggle-insider {
    display: flex;
    font-size: $text-size-s;
    background-color: white;
    border-radius: $spacing-2d;
    padding: 7px;
    min-width: 26px;
    justify-content: center;

    &.active {
      background-color: $chips-teal-50;
    }

    .check {
      transition: all 0.5s ease;
      opacity: 0;
      width: 0px;
      margin-right: 0px;

      &.active {
        opacity: 1;
        width: 20px;
        margin-right: 5px;
      }
    }
  }
}
