@import '@codametrix/ui-common/src/styles/_variables';

.filter-modal-container {
  position: absolute;

  left: 25%;
  max-width: 600px;
  min-height: 400px;

  z-index: 2;
}
.filter-container {
  background-color: $lightBackground;
  border-radius: 3px;

  user-select: none; /* Standard */

  &-applied {
    @extend .filter-container;
    margin: 10px;
    font-size: $smallText;
    min-height: 50px;
    padding-left: 20px;
  }

  &-select {
    @extend .filter-container;
    margin-left: 10px;
  }
  &-toggle {
    @extend .filter-container;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    overflow: auto;
    max-height: 450px;
  }
}

.selectable-filter {
  pointer-events: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.selectable-filter-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  font-size: $smallText;

  &.active {
    background-color: rgba($statusAutomated, 0.1);
    &:hover {
      background-color: rgba($statusAutomated, 0.1);
    }
  }
  &:hover {
    background-color: rgba($mainBgColor, 0.5);
  }
}

.extra-filter-divider {
  margin-top: 3px;
  margin-bottom: 3px;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
}

.submit-filter-btn {
  margin-left: 5px;
}
.filter-spacer {
  width: 20px;
  padding-left: 3px;
  pointer-events: none;
}
.navigation-link {
  cursor: pointer;
  color: #324ebd;
}

.check-field {
  background-color: $lightBackground;
}
.criteria-text {
  pointer-events: none;
}
