@import '@codametrix/ui-common/src/styles/_variables';

.customHeaderLabel {
  color: $tundra-400;
  font-size: 0.833571rem;
  line-height: 1rem;
  font-weight: 600;
  font-family: Roboto;
  float: left;
  margin: 0 0 0 3px;
}

.customHeaderMenuButton {
  color: $tundra-400;
  float: left;
  margin: 0 0 0 3px;
}
