@import '@codametrix/ui-common/src/styles/_variables';

.coder-note-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: $spacing-4x;
  margin: $spacing-4x 0 $spacing-4x 0;

  width: 100%;

  .note-container {
    width: 100%;

    .note-content {
      @include type-body-2;
      display: flex;
      justify-content: space-between;
      border-radius: $radius-base;
      background-color: $structural-300;
      color: $text-high-emphasis;
      padding: $spacing-2x;

      &:hover {
        background-color: $sahara-50;

        .note-actions {
          opacity: 1;
          visibility: visible;
        }
      }

      .note-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        visibility: hidden;

        .action-icon {
          border: 1px solid $tundra-300;
          border-radius: $radius-base;
          margin: 0 $spacing-2d 0 $spacing-2d;
          cursor: pointer;
        }
      }
    }

    .note-timeline {
      @include type-overline-2;
      color: $text-medium-emphasis;
      padding-top: $spacing-1x;
    }
  }

  .coder-notes-edit {
    width: 100%;
    cursor: pointer;
    fill: $polar;

    .chakra-stack {
      gap: $spacing-2x;
    }

    .coder-note-actions {
      float: right;
      margin-top: -$spacing-4x;

      .chakra-secondary-button {
        border: 1px solid $sahara-100;
      }
    }
  }

  .modal-container {
    width: 75% !important;
  }
}
