@import '@codametrix/ui-common/src/styles/_variables';

.coder-notes-send {
  width: 100%;
  margin: $spacing-4x $spacing-4x $spacing-12x $spacing-4x;
  cursor: pointer;
  fill: $polar;

  .chakra-stack {
    gap: $spacing-2x;
  }

  .coder-note-actions {
    float: right;
    margin-top: -$spacing-4x;
    .chakra-secondary-button {
      border: 1px solid $sahara-100;
    }
  }
}
