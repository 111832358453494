@import '@codametrix/ui-common/src/styles/_variables';

.form-cancel {
  size: 55px;
}

.category:hover {
  cursor: pointer;
  background-color: $ruleColor;
}

.control-container {
  font-size: $smallText;

  .filter-show-button {
    background-color: rgb(224, 225, 226);
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: hidden;
    color: rgb(100, 100, 100);
    margin-top: 1px;

    .filter-show-button-icon {
      background-color: lightgrey;
      padding: $standardRelativePad;
    }

    .filter-show-button-text {
      font-weight: $accentedWeight;
      padding-top: $standardRelativePad;
      padding-bottom: $standardRelativePad;
      padding-left: $largerRelativePad;
      padding-right: $largerRelativePad;
    }
  }

  .filter-show-button:hover {
    cursor: pointer;
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
  }

  .filter-column {
    position: relative;
    text-align: right;

    .criteria-filters {
      position: absolute;
      text-align: left;
      min-width: 150px;
      height: auto;
      right: 0;
      background-color: rgba(255, 255, 255, 0.85);
      z-index: 10;
      border: 1px solid #ddd;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.24);
      width: 250px;
      padding: 10px;
    }

    .filter-title {
      font-weight: $emphasizedWeight;
      font-size: $standardText;
      border-bottom: thin solid $deemphasizedColor;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .filter-field {
      margin-top: $largerRelativePad;
      padding-bottom: $largerRelativePad;
    }
  }
  .pop-over-view {
    width: 400px;
    background-color: white;
  }

  .filter-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: $smallRelativePad;
  }

  .form-toggle-all {
    color: green;
    border-color: green;
    font-size: large;
    margin-bottom: $smallRelativePad;
  }
}

.opaque-overlay {
  position: fixed;
  top: 0%;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(white, 0.5);
  z-index: 2;
}

.more-filters-button {
  cursor: pointer;
  border: solid 1px $lineMedium;
  border-radius: 5px;
  padding: 1px 5px;
  overflow: visible;
  color: $bodyBlack;
  background-color: #f7f8fa;
  align-items: center;
  margin-right: 15px;
  height: auto;

  &.more-filters-button:hover {
    cursor: pointer;
    border-color: $buttonColorSelectedSemiTransparent;
  }
}

.clear-filters-button {
  cursor: pointer;
  border: solid 1px $lineMedium;
  border-radius: 5px;
  padding: 1px 5px;
  overflow: visible;
  color: $bodyBlack;
  background-color: #f7f8fa;
  align-items: center;
  height: auto;

  &.clear-filters-button:hover {
    cursor: pointer;
    border-color: $buttonColorSelectedSemiTransparent;
  }
}

.dropdown {
  margin-right: 4px;
  pointer-events: none;
  min-width: 14px;
}
