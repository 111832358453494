.selectedItem {
  background-color: #fedfd726 !important;
  color: #e5927d;
  border-radius: 3px;
}
.chakra-popover__close-btn {
  position: 'inherit' !important;
  right: 'auto' !important;
}

.hoverCursor {
  cursor: pointer;
}
