@import '@codametrix/ui-common/src/styles/_variables';

.request-errors {
  display: block;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: $standardRelativePad;
}

.error-color {
  color: #bc5662;
}
