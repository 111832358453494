.calendar-container {
  display: flex;
  flex-direction: column;
  width: 330px;
}

.calendar-options {
  display: flex;
  flex-direction: row;
}
