@import '@codametrix/ui-common/src/styles/_variables';

$iconSize: 32px;
$dividerColor: #5b5b5b;
$activeTextColor: white;
$mutedTextColor: gray;

.topbar-navigation {
  box-shadow: 2px 0px 5px 1px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: row;
  width: 100%;
}

.topbar-right-section {
  display: flex;
  flex-direction: row;
}
.avatar {
  border-radius: 50%;
}

%top-bar-button {
  color: #e6e8ee;
  display: flex;
  flex-direction: row;
  align-content: center;
  float: right;
  align-items: center;
  font-size: $largeText;
  font-weight: $accentedWeight;
  margin-top: 9px;
}
.settings-button {
  @extend %top-bar-button;
  cursor: pointer;
}

.service-line-button {
  @extend %top-bar-button;
  padding-right: 0.5rem;
  cursor: pointer;
  &.service-line-button-disabled {
    cursor: default;
  }
}

.settings-button-text {
  pointer-events: none;
}

.active-top-bar-button {
  .settings-button-text {
    color: $menuActive;
  }
}

.settings-container {
  display: flex;
  flex-direction: column;

  .top-container {
    padding: 13px 20px 0px 20px;
    //position: sticky;
  }
}

.top-bar-dropdown {
  &.settings-dropdown {
    right: 5px;
    width: 310px;
  }

  &.service-line-dropdown {
    left: 60px;
    width: 112px;
  }

  position: fixed;
  top: 40px;

  bottom: 0px;
  pointer-events: none;

  .flex-card {
    pointer-events: auto;
    padding: 0.5rem 0;
    width: 100%;
    max-height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .multi-account-heading {
    display: none;
  }
  &.muliple-contexts .multi-account-heading {
    display: block;
  }

  .drop-down-heading {
    margin-top: 20px;
    margin-bottom: 2px;
    text-transform: uppercase;
    font-size: 12px;
    color: $Header;
  }

  .drop-down-heading,
  .divider-component {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
  }

  .context-display {
    width: 100%;
  }

  .item-choice {
    display: flex;
    padding: $standardPad 0;
    .item-indicator {
      width: 20px;
      text-align: center;
    }
    .item-name {
      flex: 1;
      hyphens: auto;
    }
    &.item-selected {
      background-color: $selected;
    }
  }

  .item-choice:hover {
    background-color: $buttonColorBg;
    cursor: pointer;
  }
  .item-choice:hover.org-selected {
    background-color: $selected;
    cursor: default;
  }
}

.columns {
  margin-left: 0px;
  margin-right: 0px;
}

.avatar-column {
  text-align: center;
  padding-top: 7px;
}

.user-name {
  font-weight: $accentedWeight;
  padding-bottom: 3px;
}
.name-part {
  line-break: anywhere;
}

.user-role {
  padding-bottom: 10px;
  font-size: $smallText;
}

.user-email {
  font-size: $smallText;
  padding-bottom: 15px;
}

.nav-item {
  display: block;
  width: 100%;

  height: 40px;

  .nav-item-icon {
    margin: 0 20px 0px 10px;
  }

  i.nav-item-icon {
    color: $mutedTextColor;
    font-size: 1.5rem;
  }
}

svg.search-icon.nav-item-icon,
.nav-logo {
  fill: $activeIconFill;
}

.option-container,
.avatar-container {
  svg.nav-item-icon {
    height: $iconSize;
    width: $iconSize;
  }
  border-bottom: 1px solid $dividerColor;
  padding: 1vh 0;

  .nav-item {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.cmx-avatar {
  height: 42px;
  width: 42px;
  border-radius: 50%;
}
