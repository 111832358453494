$relativeSmallText: 0.8em;
$relativeSmallerText: 0.9em;
$relativeNormalText: 1em;
$relativeLargerText: 1.1em;
$relativeLargeText: 1.2em;

$verySmallText: 0.5rem;
$smallText: 0.9rem;
$smallerText: 0.8rem;
$standardText: 0.8rem;
$largerText: 0.9rem;
$largeText: 1rem;
$largestText: 1.3rem;
$largeHeader: 1.2rem;
$largeTitle: 1.1rem;

$normalWeight: 400;
$accentedWeight: 500;
$emphasizedWeight: 600;
