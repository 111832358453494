@mixin app-resource-toggles {
  .power_features-only {
    display: none;
  }

  &.power_features-active .power_features-only {
    display: inline-block;
  }

  .case_configuration-only {
    display: none;
  }

  &.case_configuration-active .case_configuration-only {
    display: inline-block;
  }

  .users-only {
    display: none;
  }

  &.users-active .users-only {
    display: inline-block;
  }

  .support_engineer-only {
    display: none;
  }

  &.support_engineer-role .support_engineer-only {
    display: unset;
  }

  .support_engineer-only,
  .super_admin-only {
    display: none;
  }

  &.support_engineer-role .support_engineer-only,
  &.super_admin-role .super_admin-only {
    display: unset;
  }

  .dictionary-only {
    display: none;
  }

  &.dictionary-active .dictionary-only {
    display: inline-block;
  }
}
