.divider-component {
  display: flex;
  height: 5px;
  width: auto;

  &.vertical {
    transform: rotate(90deg);
    transform-origin: top left;
    width: 35px;
  }
  .divider-component-begin {
    width: 10px;
    background-image: url('../../assets/backgrounds/underline.svg');
    background-position-x: left;
  }

  .divider-component-mid {
    flex: 1;
    background: url('../../assets/backgrounds/underline-no-circles.svg');
  }

  .divider-component-end {
    width: 10px;
    background-image: url('../../assets/backgrounds/underline.svg');
    background-position-x: right;
  }
}
