@import '@codametrix/ui-common/src/styles/_variables';

.jiraTicketField {
  margin-top: $largerRelativePad;
  margin-bottom: $largerRelativePad;
  max-width: 40%;
}

.jiraTicketError {
  color: $alertTextColor;
}
