@import '@codametrix/ui-common/src/styles/_variables';

.create-case-header-section {
  .patient-encounter-section {
    @include type-heading-h4;
    display: flex;
    flex-direction: column;
    gap: $spacing-1x;
    margin-top: $spacing-5x;
    margin-bottom: $spacing-5x;

    .patient-encounter-section-secondary {
      display: flex;
      gap: $spacing-4x;
    }

    .vertical-divider {
      @include stroke-right;
    }
  }
}

.create-case-body {
  display: flex;
  flex-direction: column;
  gap: $spacing-4x;

  .field-label {
    @include type-overline-1;
    color: $text-medium-emphasis;
    margin-bottom: $spacing-base;
    width: 100%;
    display: block;
    overflow-wrap: break-word;
    word-break: break-all;
  }

  .field-data {
    @include type-body-2;
    color: $text-high-emphasis;
    display: block;
    overflow-wrap: break-word;
    word-break: break-all;
  }

  .button-section {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    width: 360px;
  }

  .horizontal-divider {
    @include stroke-bottom;
    width: 100%;
  }

  .validation-message {
    color: $errorTextColor;
  }

  .service-select {
    width: 100%;
    border: 1px solid $stroke-100;
  }

  .service-select:focus {
    width: 100%;
    border: 1px solid $stroke-100;
    outline: none;
  }

  .comments-textarea {
    border: 1px solid $tundra-200;
    @include type-body-1;
    padding: 5px;
    width: 360px;
  }

  .comments-textarea::placeholder {
    @include type-body-1;
    color: #b0ccd6;
    padding: 5px;
  }

  .comments-textarea:focus {
    border: 2px solid $stroke-100;
    outline: none;
  }

  .date-of-service {
    border: 1px solid $tundra-200;
    padding-left: 10px;
    width: 98%;
  }
  .date-of-service:focus {
    width: 98%;
    border: 1px solid $tundra-200;
    outline: none;
  }
  .chakra-input {
    height: 40px;
    border: none;
    @include type-body-2;
    color: $text-high-emphasis;
  }

  .select-section {
    .chakra-menu__menu-button {
      border-color: $tundra-200 !important;
      border-width: 1px;
      border-radius: 4px;
      height: 32px;

      &.chakra-text {
        margin-top: -1px;
      }

      &.chakra-button {
        padding-left: 22px;
        padding-right: 6px;
      }

      &:hover {
        background-color: $structural-300 !important;
      }
    }

    .button-toggle {
      border-color: $tundra-200 !important;
    }

    .menu-main-button {
      border-color: $tundra-200;
      color: $text-high-emphasis !important;
      height: 40px;
      font-weight: 500;
      width: 360px;
      display: flex;
      justify-content: space-between;

      &:hover {
        background-color: $structural-300 !important;
      }

      &.menu-main-button-active {
        background-color: $structural-300 !important;
      }

      .menu-section-chevron {
        color: $tundra-200;
        width: 14px;
      }
    }
    .menu-button-section {
      z-index: unset;
      .chakra-menu-section {
        z-index: 110;
      }
      .chakra-button-label {
        @include chip-color-teal-unlinked;
        @include type-body-2;
        color: $text-high-emphasis;
        display: block;
        overflow-wrap: break-word;
        word-break: break-all;
        padding: 5px;
        height: 24px;
      }
    }
  }
}
