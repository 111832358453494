@import '@codametrix/ui-common/src/styles/_variables';

.data-grid {
  .primary-info {
    font-size: $smallText;
    display: block;
    font-weight: $accentedWeight;
  }

  .secondary-info {
    font-size: $smallerText;
  }

  .deemphasized {
    color: $deemphasizedColor;
  }

  .gap-right {
    margin-right: $standardPad;
  }
}
