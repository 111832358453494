.navItem {
  color: #e5927d;
  hr {
    border-color: #e5927d;
  }
}

.hoverCursor {
  cursor: pointer;
}

.popOverSelected {
  background-color: #e5927d !important;
  path {
    fill: #ffffff;
  }
}

.dashboardSelection {
  .chakra-popover__popper {
    padding-left: 10px;
  }
}
