@import '@codametrix/ui-common/src/styles/_variables';

.generic-cases-modal {
  color: $text-high-emphasis !important;

  .main-view {
    display: flex;
    gap: 2vw;
    height: 40vw;
  }

  .encounter-content {
    margin-bottom: 1vw;
  }

  .main-content {
    border-top: 1px solid $tundra-100;
  }

  .merge-actions-section {
    border-right: 1px solid $tundra-100;
    padding-right: 1vw;
    max-height: 39vw;
    overflow: auto;
    width: 55vw;
    height: 40vw;
  }

  .note-plain {
    width: 100%;
    height: 100%;
  }

  .note-section {
    width: 35vw;
    height: 40vw;
    overflow: auto;
  }

  .note-unavaialable {
    border: 1px solid $tundra-100;
    color: $text-low-emphasis;
    justify-content: center;
    padding: $spacing-3x;
    display: flex;
    justify-content: center;
    margin: $spacing-5x;
    text-align: center;
  }

  .case-notes {
    width: 35vw;
    height: 40vw;
  }
}
