.datepicker-component {
  .react-datepicker {
    overflow: hidden;
    min-height: 273px;
    max-height: 308px;
    width: 280px;
    padding: 0px 14px 28.5px 14px;
    background: #ffffff;
    border: 1px solid #d1f3ff;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }

  .react-datepicker__header {
    border-radius: 0;
    background-color: #ffffff;
    border-bottom: none;
    padding: 0px;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.75px solid #d1f3ff;
    border-left: 0.75px solid #d1f3ff;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    margin: 0px 8px 8px 8px;
  }

  .react-datepicker__day-name {
    color: #7498a6;
    font-weight: 400;
    font-size: 11.67px;
    line-height: 14px;
    width: 14px;
    height: 14px;
  }

  .react-datepicker__month--in-selecting-range,
  .react-datepicker__day--in-selecting-range {
    background-color: #fedfd7 !important;
    color: #203d48;
  }

  .react-datepicker__year-text,
  .react-datepicker__day {
    box-sizing: border-box;
    padding: 3.75px;
    margin: unset;
    gap: 17.25px;
    width: 36px;
    height: 34.4px;
    left: 225.75px;
    top: 143.8px;
    background: #ffffff;
    border-right: 0.75px solid #d1f3ff;
    font-weight: 400;
    font-size: 11.67px;
    color: #203d48;
    outline: none;
    border-radius: 0px;
    &:hover {
      background: #fedfd7;
      border: 1px solid #fedfd7;
      border-radius: 0px;
    }
  }

  .react-datepicker__year-text--selected,
  .react-datepicker__month--selected,
  .react-datepicker__day--selected {
    background: #e5927d !important;
    border-radius: 0px;
    box-shadow: 'none';
    color: #ffffff !important;
  }

  .react-datepicker__year--in-range,
  .react-datepicker__month--in-range,
  .react-datepicker__day--in-range {
    background: #fedfd7 !important;
    border-radius: 0px;
    color: #203d48 !important;
  }

  .react-datepicker__month-container {
    float: none;
    height: fit-content;
  }

  .react-datepicker__month-wrapper {
    border-left: 0.75px solid #d1f3ff;
  }

  .react-datepicker__month {
    border-top: 0.75px solid #d1f3ff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
  }

  .react-datepicker__month-text--keyboard-selected {
    background: #ffffff;
    color: #203d48;
  }

  .react-datepicker__month .react-datepicker__month-text {
    border-right: 0.75px solid #d1f3ff;
    border-bottom: 0.75px solid #d1f3ff;
    width: 62px;
    height: 50px;
    margin: 0px;
    display: inline-flex;
    justify-content: center;
    box-sizing: border-box;
    outline: none;
    align-items: center;
    border-radius: 0px;
    color: #203d48;
    font-size: 11.67px;
    font-weight: 400;
    &:hover {
      background: #fedfd7;
      border: 1px solid #fedfd7;
      border-radius: 0px;
    }
  }

  .react-datepicker__year {
    margin: 0;
  }

  .react-datepicker__year-wrapper {
    border-top: 0.75px solid #d1f3ff;
    border-left: 0.75px solid #d1f3ff;
    max-width: fit-content;
  }

  .react-datepicker__year .react-datepicker__year-text {
    border-bottom: 0.75px solid #d1f3ff;
    width: 62px;
    height: 50px;
    margin: unset;
    display: grid;
    text-align: center;
    align-items: center;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: #203d48;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .react-datepicker__year--range-start,
  .react-datepicker__year--range-end,
  .react-datepicker__month--range-start,
  .react-datepicker__month--range-end,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background-color: #e5927d !important;
    color: #ffffff !important;
    border-radius: 0px;
  }
}
