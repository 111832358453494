$smallestRelativePad: 0.1rem;
$smallRelativePad: 0.2rem;
$standardRelativePad: 0.3rem;
$largerRelativePad: 0.4rem;
$largestRelativePad: 0.5rem;

$standardPad: 6px;
$smallPad: 3px;
$standardBorder: 1px;
$standardBorderRadius: 3px;
$largeBorderRadius: 6px;

$textPad: 3px;

$relativeSmallPad: 0.1rem;
$relativeSmallerPad: 0.2rem;
$relativeStandardPad: 0.3rem;
$relativeLargePad: 0.4rem;
$relativeLargerPad: 0.5rem;
$relativeGiantPad: 1.5rem;
