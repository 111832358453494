@import '@codametrix/ui-common/src/styles/_variables';
@import '../../styles/resources';
@import '../../views/patient-timeline/patient-timeline-styles.scss';

.cmx-application-chakra {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  &.cmx-application-loaded {
    opacity: 1;

    .centered-application,
    .loading-indicator {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.5s, opacity 0.5s linear;
    }
  }

  .loading-view,
  .choice-view {
    display: none;
  }

  .loaded-application {
    display: block;
  }

  &.cmx-application-loading {
    .loading-view {
      display: block;
      z-index: 2000;
    }
    .choice-view {
      display: none;
    }
  }

  &.cmx-application-choose-context {
    .choice-view {
      display: block;

      .context-choice-form {
        margin: 0 auto;
      }
      .service-line-choice-form {
        margin: 0 auto;
      }
    }
    .loading-view {
      display: none;
    }
  }

  &.cmx-application-masked {
    opacity: 1;
    background: $lightBackground;

    .layout-main,
    .layout-sidebar {
      display: none;
    }
  }

  .centered-application {
    background: rgba(247, 248, 249, 0.95);
    bottom: 0;
    cursor: default;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .centered-application-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: row;
    margin: 0;
    align-items: center;
  }

  .check-box {
    width: 100px;
    height: 100px;
    margin-bottom: 1em;
  }

  &.mask-sensitive-data {
    .sensitive-data {
      color: transparent;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

      .highlighted-segment,
      .highlighted-text,
      &.highlighted-segment,
      &.highlighted-text {
        text-shadow: 0 0 10px white;
        color: transparent;
        opacity: 0.5;
      }
    }
  }

  &.cmx-application-chakra-masked {
    .layout-main,
    .layout-navbar {
      opacity: 0.8;
      pointer-events: none;
    }
    .centered-application {
      background: $scrim-color-light;
      opacity: 1;
    }
  }
}

.cmx-application {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  &.cmx-application-loaded {
    opacity: 1;

    .centered-application,
    .loading-indicator {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.5s, opacity 0.5s linear;
    }
  }

  .loading-view,
  .choice-view {
    display: none;
  }

  .loaded-application {
    display: block;
  }

  &.cmx-application-loading {
    .loading-view {
      display: block;
      z-index: 2000;
    }
    .choice-view {
      display: none;
    }
  }

  &.cmx-application-choose-context {
    .choice-view {
      display: block;

      .context-choice-form {
        margin: 0 auto;
      }
      .service-line-choice-form {
        margin: 0 auto;
      }
    }
    .loading-view {
      display: none;
    }
  }

  &.cmx-application-masked {
    opacity: 1;
    background: $lightBackground;

    .layout-main,
    .layout-sidebar {
      display: none;
    }
  }

  .centered-application {
    background: rgba(247, 248, 249, 0.95);
    bottom: 0;
    cursor: default;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .centered-application-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: row;
    margin: 0;
    align-items: center;
  }

  .check-box {
    width: 100px;
    height: 100px;
    margin-bottom: 1em;
  }

  &.mask-sensitive-data {
    .sensitive-data {
      color: transparent;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

      .highlighted-segment,
      .highlighted-text,
      &.highlighted-segment,
      &.highlighted-text {
        text-shadow: 0 0 10px white;
        color: transparent;
        opacity: 0.5;
      }
    }
  }
}

#appRoot {
  @include app-resource-toggles;
}

.nav-link {
  font-size: $largeTitle !important;
}
