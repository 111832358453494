@import '@codametrix/ui-common/src/styles/_variables';

.merge-cases-modal {
  color: $text-high-emphasis !important;

  .case-alert-icon-info {
    color: $tundra-300;
  }

  .main-view {
    display: flex;
    gap: 2vw;
  }

  .encounter-content {
    margin-bottom: 1vw;
  }

  .main-content {
    border-top: 1px solid $tundra-100;
  }

  .merge-actions-section {
    border-right: 1px solid $tundra-100;
    padding-right: 1vw;
  }

  .action-buttons {
    display: flex;
    gap: 15px;
  }

  .grid-title-section {
    display: flex;
  }

  .grid-title {
    margin-top: 2vw;
    @include type-subtitle-1;
  }

  .grid-content {
    margin: 1.9vw 0vw 1vw 0.2vw;
    @include type-body-2;
  }
}
