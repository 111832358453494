@import '@codametrix/ui-common/src/styles/_variables';

.patient-timeline-body {
  .empty-message-error {
    color: $alert-500;
  }

  .filter-type-active {
    .chakra-menu__menu-button {
      background-color: $structural-300 !important;
    }
  }

  .filters-section {
    .filter-active {
      background-color: $structural-300 !important;
    }

    .chakra-menu__menu-button {
      border-color: $tundra-200 !important;
      border-width: 1px;
      border-radius: 4px;
      height: 32px;

      &.chakra-text {
        margin-top: -1px;
      }

      &.chakra-button {
        padding-left: 22px;
        padding-right: 6px;
      }

      &:hover {
        background-color: $structural-300 !important;
      }
    }

    .button-toggle {
      border-color: $tundra-200 !important;
    }

    .menu-main-button {
      border-color: $tundra-200;
      color: $text-high-emphasis !important;
      height: 32px;
      font-weight: 500;

      &:hover {
        background-color: $structural-300 !important;
      }

      &.menu-main-button-active {
        background-color: $structural-300 !important;
      }

      .menu-section-chevron {
        color: $tundra-200;
        width: 14px;
      }
    }
    display: flex;
  }

  .tenantTimezoneSection {
    display: flex;
    flex-direction: column;

    .field-label {
      @include type-overline-1;
      color: $text-medium-emphasis;
      text-transform: uppercase;
      margin-bottom: $spacing-base;
      width: 100%;
      display: block;
      overflow-wrap: break-word;
      word-break: break-all;
    }

    .field-data {
      @include type-body-2;
      color: $text-high-emphasis;
      display: block;
      overflow-wrap: break-word;
      word-break: break-all;
    }
  }

  .order-notes-comment-section {
    margin-top: 0px;

    .comment-item {
      margin-top: 0px;
    }

    .highlight-comment-item {
      display: inline-block;
      border: 1px solid $tundra-200;
      padding: 0px;
    }
  }
}
