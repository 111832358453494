.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: white;
}

.my-status:hover > .my-status-open {
  max-height: 500px;
}

.my-status-open {
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.3s ease;
}
