#top-bar-icon {
  font-size: 1.7em;
  margin-left: 0.08rem;
  color: #5755d9;
}

.main-text {
  font-size: 0.69rem;
  margin-left: 0.26rem;
  color: white;
}

#avatar-icon {
  font-size: 3.8rem;
  color: #5755d9;
  margin-left: -1.17rem;
  margin-top: -0.6rem;
}

#avatar-text {
  margin-top: 0.8rem;
  margin-left: 0.25rem;
  font-size: 1.65rem;
  color: white;
}

.settings-icon {
  display: none;
}

.active-top-bar-button {
  .main-icon {
    color: #6968d3;
  }
}

.active-settings-icon {
  display: block;
  font-size: 1.9em;
  color: rgba(255, 255, 255, 0.644);
}
