@import '@codametrix/ui-common/src/styles/_variables';

.loader-wrapper {
  z-index: 100;
  vertical-align: middle;
  margin: auto auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.loading-indicator-inner {
  padding: $spacing-3x;
  margin: 0 auto;
}

.loading-indicator-graphic {
  height: 150px;
  width: 150px;
  display: inline;
}

.text {
  width: max-content;
  margin: auto;
}
