// interface components
$sidebarColor: #22254f;
$lightRuleColor: #ebebeb;
$ruleColor: #ddd;
$exteriorHeaderColor: #fff;
$mainBgColor: #eaebef;
$lightBackground: #f7f8fa;
$almostLightBackground: #efefef;
$offwhiteBackground: #ccc;
$areaTextField: #f0f3f7;
$lightBgColor: #f7f8fa;

$mainNavigationHighlight: #3292a8;

$lineLight: #e9e9f0;
$lineMedium: #ced4eb;
$lineDark: #c1c5d5;

//interactive

$buttonColor: #5573e9;
$buttonColorSelected: #5170ec;
$buttonColorSelectedSemiTransparent: rgba(81, 112, 236, 0.5);
$buttonColorBg: #f2f3f5; //BG for hovering primary button, or regular button without hover
$primaryButtonTextColor: #ffffff;
$disabledButtonColor: #c9caca;

$selected: #d9e0fc;
$menuActive: #3292a8;
$menuOff: #ced4eb;

// text
$titleBlack: #282a2b;
$bodyBlack: #3e3f40;
$bodyGrey: #4d4d4d;

$linkColor: #324ebd;

$disabled: #e2e3e3;
$Contrast: #fafafc;
$Header: #9e9e9e;

$lightGreyText: #696a6b;

// status colors
$infoColor: #bef;
$infoTextColor: #059;

$darkInfoTextColor: #aaa;
$standardTextColor: #3b4351;

$successColor: #398c68;
$successTextColor: #398c68;

$warningColor: #feefb3;
$warningTextColor: #9f6000;
$warningBackgroundColor: lightyellow;

$errorColor: #ffbaba;
$errorTextColor: #bc5662;
$alertTextColor: red;

//icons
$iconColor: #aaa;
$activeIconFill: #fff;
$darkIconFill: #5573e9;

$deemphasizedColor: #888;

//case status
$statusInProgress: #d67670;
$statusWaiting: #d67670;
$statusAutomated: #39c1ce;
$statusManual: #43488a;
$statusCancel: #f5b940;
$statusComplete: #43488a;
$statusUpdatedToManual: #88438a;
$areaLightBackground: #f7f8fa;
$statusReadyForCoding: #43488a;

//codes
$cptColor: #a85600;
$cptBackgroundColor: rgba(168, 86, 0, 0.2);
$cptUnlinkedColor: rgba(168, 86, 0, 0.7);
$cptMidColor: rgba(168, 86, 0, 0.3);

$icdColor: #9c5a99;
$icdBackgroundColor: rgba(156, 90, 153, 0.2);
$icdUnlinkedColor: rgba(156, 90, 153, 0.7);
$icdMidColor: rgba(156, 90, 153, 0.3);

$modifierColor: #4370d0;
$modifierBackgroundColor: rgba(67, 112, 208, 0.2);
$modifierUnlinkedColor: rgba(67, 112, 208, 0.7);
$modifierMidColor: rgba(67, 112, 208, 0.3);

$belowThreshold: #9e9e9e;
$belowThresholdBackgroundColor: rgba(158, 158, 158, 0.2);

//charts
$charColorLight: #aab9f4;
$chartColorMedium: #7173dc;
$chartColorDark: #775a9c;

$alertTextColor: red;
$techColor: #baedff;
$blackColor: #24292e;

//Side Nav Icon colors
$lightIconColor: #cdcdcd;
$iconColor: #e6e8ee;
$darkIconColor: #ced4eb;
$activeIconFill: #fff;

//ui-component specific
$interactiveHover: #f2f3f5;
$lightTextColor: #777;
$tableHeaderColor: #324ebd;

//process status
$poppy-base-80: #dc483e;

// search bar
$search-bar-view-container-v2: #e8fbf7;
