//settings for all buttons
$button-padding: 12px, 16px; /*Should end up 40px tall*/

//primary buttons are the main CTA for the page
$button-primary-text-color: $text-light;

@mixin primary-button-base {
  @include type-subtitle-1;
  padding: $button-padding;
  border: 1px solid transparent;
  color: $button-primary-text-color;
  border-radius: $radius-base;
}

@mixin button-primary-resting {
  @include primary-button-base;
  background-color: $sahara-100;
  color: black;
}

@mixin button-select-resting {
  @include primary-button-base;
  background-color: $sahara-50;
  color: black;
}

@mixin button-primary-hover {
  @include primary-button-base;
  background-color: $sahara-200;
}

@mixin button-primary-pressed {
  @include primary-button-base;
  background-color: $sahara-300;
}

@mixin button-primary-active {
  @include primary-button-base;
  background-color: $sahara-50;
}

//secondary buttons have an outline
@mixin secondary-button-base {
  @include type-subtitle-1;
  padding: $button-padding;
  border-radius: $radius-base;
}

@mixin button-secondary-resting {
  @include secondary-button-base;
  background-color: transparent;
  color: $sahara-100 !important;
  border: 1px solid $sahara-100;
}

@mixin button-secondary-hover {
  @include secondary-button-base;
  background-color: $sahara-50;
  color: $sahara-100;
  border: 1px solid $sahara-100;
}

@mixin button-secondary-pressed {
  @include secondary-button-base;
  background-color: $sahara-50;
  color: $sahara-200;
  border: 1px solid $sahara-200;
}

@mixin button-secondary-active {
  @include secondary-button-base;
  background-color: transparent;
  color: $sahara-50;
  border: 1px solid $sahara-50;
}

//tertiary buttons look like links
@mixin tertiary-button-base {
  @include type-subtitle-1;
  padding: $button-padding;
  border-radius: $radius-base;
  border: 1px solid transparent;
}

@mixin button-tertiary-resting {
  @include tertiary-button-base;
  background-color: transparent;
  color: $sahara-100;
}

@mixin button-tertiary-hover {
  @include tertiary-button-base;
  background-color: $sahara-50;
  color: $sahara-100;
}

@mixin button-tertiary-pressed {
  @include tertiary-button-base;
  background-color: $sahara-50;
  color: $sahara-200;
}

@mixin button-tertiary-active {
  @include tertiary-button-base;
  background-color: transparent;
  color: $sahara-50;
}

@mixin chakra-button {
  @include primary-button-base;
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  font-weight: 500;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 2rem;
  min-width: 2rem;
  font-size: 0.875rem;
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
  cursor: pointer;
}

.chakra-primary-button {
  @include primary-button-base;
  @include chakra-button;
  margin-left: 8px;
  background: $sahara-100;
}

.chakra-primary-button:hover {
  @include button-primary-hover;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
}

.chakra-select-button {
  @include primary-button-base;
  @include chakra-button;
  margin-left: 8px;
  font-size: $text-size-s !important;
  font-weight: $text-weight-regular !important;
  background-color: white;
  color: black;
}

.chakra-select-button:hover {
  @include button-select-resting;
}

.chakra-secondary-button {
  @include button-secondary-resting;
  @include chakra-button;
}

.chakra-secondary-button:hover {
  @include button-secondary-hover;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
}

.chakra-primary-button.disabled,
.chakra-secondary-button.disabled {
  opacity: 0.6;
  pointer-events: none;
}
