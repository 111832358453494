@import '@codametrix/ui-common/src/styles/_variables';

.admin-container {
  display: flex;
  flex-direction: column;
  max-width: 1280 px;
  margin-left: 270px;
  .heading-row {
    padding: 0.7rem 0.5rem 0.7rem 0;
    .icon-link {
      margin-left: $standardPad;
    }
  }

  .admin-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .data-grid td {
      vertical-align: text-bottom;
    }
  }
  .tab {
    margin-bottom: 1.1em;
  }

  .action-button-container {
    text-align: right;
  }
}
