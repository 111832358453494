@import '@codametrix/ui-common/src/styles/_variables';

.pageable-list-view {
  height: auto;
  flex: 1;

  .criteria-filters {
    display: none;
  }
  &.filter-open {
    .criteria-filters {
      display: block;
    }
  }

  .control-container {
    font-size: $smallText;
  }
  .heading-row {
    padding: 0;
  }
  .paddles {
    padding: 0 $standardPad;
  }

  .pagination-controls {
    text-align: center;
    font-size: $smallText;
    .pagination-container {
      margin: 0 auto;
    }
  }

  .data-grid {
    &.table td,
    &.table th {
      padding: $standardRelativePad $smallRelativePad;
    }

    th {
      font-size: $smallText;
      border: 1px dotted transparent;
    }

    td {
      vertical-align: baseline;
      border-collapse: collapse;
      border: 1px dotted #dfdfdf;
      font-size: $smallText;
    }

    .decision-reason {
      display: none;
    }
    .case-has-reason {
      .decision-reason {
        display: inline-block;
      }
    }
    // headers set column widths

    .patient-m-r-n-header {
      min-width: 160px;
    }

    .tooltip::after {
      max-width: 350px;
    }

    .tooltip:focus::after,
    .tooltip:hover::after {
      transition: opacity 200ms;
      transition-delay: 400ms;
    }

    th.date-of-service-header,
    td.date-of-service-cell {
      padding-left: 15px;
    }
  }
}
