//font defined in main.scss as body style

//text size, 1.2 minor third scale
$text-size-xxxl: 2.074rem; /*29.03px*/
$text-size-xxl: 1.728rem; /*24.19px*/
$text-size-xl: 1.44rem; /*20.16px*/
$text-size-l: 1.2rem; /*16.80px*/
$text-size-base: 14px;
$text-size-s: 0.833rem; /*11.67px*/
$text-size-xs: 0.694rem; /*9.72px*/

//line height
$line-height-xxxl: 2.42rem; /*34px*/
$line-height-xxl: 2rem; /*28px*/
$line-height-xl: 1.71rem; /*24px*/
$line-height-l: 1.42rem; /*20px*/
$line-height-base: 1.14rem; /*16px*/
$line-height-s: 1rem; /*14px*/
$line-height-xs: 0.85rem; /*12px*/

//letter spacing
$letter-spacing-condensed: 0.9rem;
$letter-spacing-base: normal;
$letter-spacing-wide: 1.1rem;
$letter-spacing-xwide: 1.2rem;

//text weight
$text-weight-regular: 400;
$text-weight-medium: 600;
$text-weight-bold: 700;

//text-styles, please use only these sizes
@mixin type-heading-h1 {
  font-size: $text-size-xxxl;
  font-weight: $text-weight-medium;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-xxxl;
}
@mixin type-heading-h2 {
  font-size: $text-size-xxl;
  font-weight: $text-weight-medium;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-xxl;
}
@mixin type-heading-h3 {
  font-size: $text-size-xl;
  font-weight: $text-weight-medium;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-xl;
}
@mixin type-heading-h4 {
  font-size: $text-size-l;
  font-weight: $text-weight-medium;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-l;
}

@mixin type-subtitle-1 {
  font-size: $text-size-base;
  font-weight: $text-weight-bold;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-base;
}

@mixin type-subtitle-2 {
  font-size: $text-size-s;
  font-weight: $text-weight-bold;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-s;
}

@mixin type-body-1 {
  font-size: $text-size-base;
  font-weight: $text-weight-regular;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-base;
}
@mixin type-body-2 {
  font-size: $text-size-s;
  font-weight: $text-weight-regular;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-s;
}

@mixin type-caption-1 {
  font-size: $text-size-s;
  font-weight: $text-weight-bold;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-s;
}
@mixin type-caption-2 {
  font-size: $text-size-s;
  font-weight: $text-weight-regular;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-s;
} /*right now this is the same specs as Body 2*/

@mixin type-overline-1 {
  font-size: $text-size-xs;
  font-weight: $text-weight-medium;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-xs;
}
@mixin type-overline-2 {
  font-size: $text-size-xs;
  font-weight: $text-weight-regular;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-xs;
}
@mixin type-artistic-subtitle-2 {
  font-size: $text-size-s;
  font-weight: $text-weight-medium;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-s;
}

@mixin type-artistic-body-2 {
  font-size: $text-size-s;
  font-weight: $text-weight-regular;
  letter-spacing: $letter-spacing-base;
  line-height: $line-height-s;
}
