.clear-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.bottomless-divider {
  border-top: 1px solid rgb(241, 243, 245);
  height: 0.1px;
  margin-top: 8px;
}

.dropdown-case_status {
  width: 200px;
}
