.day-checkbox {
  display: inline-block;
  position: relative;
  margin-right: 30px;
}

.day-checkbox input[type='checkbox'] {
  display: none;
}

.day-checkbox input[type='checkbox']:checked + .checkmark {
  background-color: #e5927d;
}

.day-checkbox input[type='checkbox']:not(:checked) + .checkmark {
  background-color: lightgrey;
}

.day-checkbox input[type='checkbox']:checked + .checkmark .day-label {
  color: #fff;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.day-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
  font-weight: bold;
  font-size: 14px;
  pointer-events: none;
}

.hour-input {
  text-align: left;
  border: 1px solid;
  border-radius: 3;
  display: inline-block;
  max-width: 100px;
  margin-right: 15px;
  padding-right: 20px;
}
