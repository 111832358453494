@use "sass:math";

//spacing
$spacing-2d: 2px; /*2px*/
$spacing-6d: $spacing-2d * 3; /*6px*/

$spacing-base: 4px;
$spacing-1x: $spacing-base * 1; /*4px*/
$spacing-2x: $spacing-base * 2; /*8px*/
$spacing-3x: $spacing-base * 3; /*12px*/
$spacing-4x: $spacing-base * 4; /*16px*/
$spacing-5x: $spacing-base * 5; /*20px*/
$spacing-6x: $spacing-base * 6; /*24px*/
$spacing-7x: $spacing-base * 7; /*28px*/
$spacing-8x: $spacing-base * 8; /*32px*/
$spacing-9x: $spacing-base * 9; /*36px*/
$spacing-10x: $spacing-base * 10; /*40px*/
$spacing-11x: $spacing-base * 11; /*44px*/
$spacing-12x: $spacing-base * 12; /*48px*/
$spacing-13x: $spacing-base * 13; /*52px*/
$spacing-19x: $spacing-base * 19; /*76px*/

//corner radius
$radius-base: 3px;
$radius-2x: $radius-base * 2;
$radius-3x: $radius-base * 3;
$radius-9x: $radius-base * 9;

//elevation, inspired by MD3
$shadow-umbra-opacity: 14%;
$shadow-penumbra-opacity: 12%;
$shadow-ambiant-opacity: 20%;
$shadow-color: $tundra-500;

$shadow-umbra: rgba($shadow-color, $shadow-umbra-opacity);
$shadow-penumbra: rgba($shadow-color, $shadow-penumbra-opacity);
$shadow-ambiant: rgba($shadow-color, $shadow-ambiant-opacity);

@mixin elevation-l1 {
  box-shadow: 0 3px 3px -2px $shadow-ambiant, 0 3px 4px 0 $shadow-umbra,
    0 1px 8px 0 $shadow-penumbra;
} /*3dp*/
@mixin elevation-l2 {
  box-shadow: 0 3px 5px -1px $shadow-ambiant, 0 6px 10px 0 $shadow-umbra,
    0 1px 18px 0 $shadow-penumbra;
} /*6dp*/
@mixin elevation-l3 {
  box-shadow: 0 5px 6px -3px $shadow-ambiant, 0 9px 12px 1px $shadow-umbra,
    0 3px 16px 2px $shadow-penumbra;
} /*9dp*/
@mixin elevation-l4 {
  box-shadow: 0 6px 7px -4px $shadow-ambiant, 0 11px 15px 1px $shadow-umbra,
    0 4px 20px 3px $shadow-penumbra;
} /*11dp*/
@mixin elevation-l5 {
  box-shadow: 0 8px 9px -5px $shadow-ambiant, 0 15px 22px 2px $shadow-umbra,
    0 6px 28px 5px $shadow-penumbra;
} /*15dp*/
@mixin tab-elevation-l1 {
  box-shadow: 3px 3px 3px -2px $shadow-ambiant, 3px 3px 3px 0 $shadow-umbra,
    3px -1px 3px 0 $shadow-penumbra;
}
//strokes
@mixin stroke-bottom {
  border-bottom: 1px solid $stroke-100;
}
@mixin stroke-top {
  border-top: 1px solid $stroke-100;
}
@mixin stroke-left {
  border-left: 1px solid $stroke-100;
}
@mixin stroke-right {
  border-right: 1px solid $stroke-100;
}

@mixin tab-base-stroke-bottom {
  border-bottom: 1px solid $stroke-200;
}
@mixin tab-active-stroke-bottom {
  border-bottom: 3px solid $sahara-200;
}
@mixin tab-hover-stroke-bottom {
  border-bottom: 3px solid $sahara-50;
}
@mixin tab-resting-stroke-bottom {
  border-bottom: 3px solid transparent;
}

//icon sizes
$icon-size-xs: 12px;
$icon-size-s: 16px;
$icon-size-m: 20px;
$icon-size-l: 32px;

//icon colors
$icon-color-standard: $tundra-300;

//chip height - includes codes and tags
$chip-height-small: 16px; /*18px reduced to account for stroke*/
$chip-height-medium: 22px; /*24px reduced to account for stroke*/

$chip-radius: 25px;

@mixin chip-layout-small {
  @include type-body-2;
  height: $chip-height-small;
}
@mixin chip-layout-medium {
  @include type-body-1;
  padding: 4px 12px;
  height: $chip-height-medium;
}

@mixin chip-rounded-both {
  border-radius: $chip-radius;
  padding: 2px 8px;
  .code-text {
    margin-top: -0.04em;
  }
}
@mixin chip-rounded-left {
  border-radius: $chip-radius 0 0 $chip-radius;
  margin-right: 0;
  padding: 2px 4px 2px 8px;
  .code-text {
    margin-top: -0.04em;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding: 4px 4px 2px 8px !important;
  }
}
@mixin chip-rounded-right {
  border-radius: 0 $chip-radius $chip-radius 0;
  margin-left: 0;
  padding: 2px 8px 2px 4px;
  .code-text {
    margin-top: -0.04em;
  }
  /* IE 10+ */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding: 2px 18px 2px 4px !important;
  }
}

@mixin tag-rounded-both {
  border-radius: $radius-base;
  padding: 2px 8px;

  .code-text {
    margin-top: -0.04em;
  }
}

@mixin tag-rounded-both-no-border {
  @include tag-rounded-both;
  border: 0px !important;
}

//chip colors - includes codes and tags
@mixin chip-color-grey {
  background-color: $chips-grey-50;
  color: $chips-grey-500 !important;

  .icon {
    fill: $chips-grey-500;
  }
  a {
    color: $chips-grey-500;
  }
}
@mixin chip-color-brown {
  background-color: $chips-brown-50;
  color: $chips-brown-500 !important;

  .icon {
    fill: $chips-brown-500;
  }

  a {
    color: $chips-brown-500;
  }
}
@mixin chip-color-green {
  background-color: $chips-green-50;
  color: $chips-green-500 !important;

  .icon {
    fill: $chips-green-500;
  }

  a {
    color: $chips-green-500;
  }
}
@mixin chip-color-yellow {
  background-color: $chips-yellow-50;
  color: $chips-yellow-500 !important;

  .icon {
    fill: $chips-yellow-500;
  }

  a {
    color: $chips-yellow-500;
  }
}
@mixin chip-color-red {
  background-color: $chips-red-50;
  color: $chips-red-500 !important;

  .icon {
    fill: $chips-red-500;
  }

  a {
    color: $chips-red-500;
  }
}
@mixin chip-color-blue {
  background-color: $chips-blue-50;
  color: $chips-blue-500 !important;

  .icon {
    fill: $chips-blue-500;
  }

  a {
    color: $chips-blue-500;
  }
}
@mixin chip-color-teal {
  background-color: $chips-teal-50;
  color: $chips-teal-500 !important;

  .icon {
    fill: $chips-teal-500;
  }

  a {
    color: $chips-teal-500;
  }
}
@mixin chip-color-plum {
  background-color: $chips-plum-50;
  color: $chips-plum-500 !important;

  .icon {
    fill: $chips-plum-500;
  }
  a {
    color: $chips-plum-500;
  }
}

@mixin chip-color-plum-outline {
  background-color: $polar;
  color: $chips-plum-500;

  .icon {
    fill: $chips-plum-500;
  }
  a {
    color: $chips-plum-500;
  }
}

@mixin chip-color-plum-outline-hover {
  background-color: $polar;
  color: $chips-plum-500;

  .icon {
    fill: $chips-plum-500;
  }
  a {
    color: $chips-plum-500;
  }
}

@mixin chip-color-plum-outline-focus {
  background-color: $chips-plum-50;
  color: $chips-plum-500;

  .icon {
    fill: $chips-plum-500;
  }
  a {
    color: $chips-plum-500;
  }
}

//dark version tags and chip colors
@mixin chip-color-grey-dk {
  background-color: $chips-grey-500;
  color: $chips-grey-50;
  border: 1px solid transparent;

  .icon {
    fill: $chips-grey-50;
  }

  a {
    color: $chips-grey-50;
  }
}
@mixin chip-color-brown-dk {
  background-color: $chips-brown-500;
  color: $chips-brown-50;
  border: 1px solid transparent;

  .icon {
    fill: $chips-brown-50;
  }

  a {
    color: $chips-brown-50;
  }
}
@mixin chip-color-green-dk {
  background-color: $chips-green-500;
  color: $chips-green-50;
  border: 1px solid transparent;

  .icon {
    fill: $chips-green-50;
  }

  a {
    color: $chips-green-50;
  }
}
@mixin chip-color-yellow-dk {
  background-color: $chips-yellow-500;
  color: $chips-yellow-50;
  border: 1px solid transparent;

  .icon {
    fill: $chips-yellow-50;
  }

  a {
    color: $chips-yellow-50;
  }
}
@mixin chip-color-red-dk {
  background-color: $chips-red-500;
  color: $chips-red-50;
  border: 1px solid transparent;

  .icon {
    fill: $chips-red-50;
  }

  a {
    color: $chips-red-50;
  }
}
@mixin chip-color-blue-dk {
  background-color: $chips-blue-500;
  color: $chips-blue-50;
  border: 1px solid transparent;

  .icon {
    fill: $chips-blue-50;
  }

  a {
    color: $chips-blue-50;
  }
}
@mixin chip-color-teal-dk {
  background-color: $chips-teal-500;
  color: $chips-teal-50;
  border: 1px solid transparent;

  .icon {
    fill: $chips-teal-50;
  }

  a {
    color: $chips-teal-50;
  }
}
@mixin chip-color-plum-dk {
  background-color: $chips-plum-500;
  color: $chips-plum-50 !important;
  border: 1px solid transparent;

  .icon {
    fill: $chips-plum-50;
  }

  a {
    color: $chips-plum-50;
  }
}

//unlinked tags and chip colors
@mixin chip-color-grey-unlinked {
  background-color: lighten($chips-grey-50, 1%);
  color: lighten($chips-grey-500, 30%);
  border: 1px solid transparent;

  .icon {
    fill: lighten($chips-grey-500, 30%);
  }
  a {
    color: lighten($chips-grey-500, 30%);
  }
}
@mixin chip-color-brown-unlinked {
  background-color: lighten($chips-brown-50, 1%);
  color: lighten($chips-brown-500, 30%);
  border: 1px solid transparent;

  .icon {
    fill: lighten($chips-brown-500, 30%);
  }

  a {
    color: lighten($chips-brown-500, 30%);
  }
}
@mixin chip-color-green-unlinked {
  background-color: lighten($chips-green-50, 1%);
  color: lighten($chips-green-500, 30%);
  border: 1px solid transparent;

  .icon {
    fill: lighten($chips-green-500, 30%);
  }

  a {
    color: lighten($chips-green-500, 30%);
  }
}
@mixin chip-color-yellow-unlinked {
  background-color: lighten($chips-yellow-50, 1%);
  color: lighten($chips-yellow-500, 30%);
  border: 1px solid transparent;

  .icon {
    fill: lighten($chips-yellow-500, 30%);
  }

  a {
    color: lighten($chips-yellow-500, 30%);
  }
}
@mixin chip-color-red-unlinked {
  background-color: lighten($chips-red-50, 1%);
  color: lighten($chips-red-500, 30%);
  border: 1px solid transparent;

  .icon {
    fill: lighten($chips-red-500, 30%);
  }

  a {
    color: lighten($chips-red-500, 30%);
  }
}
@mixin chip-color-blue-unlinked {
  background-color: lighten($chips-blue-50, 1%);
  color: lighten($chips-blue-500, 30%);
  border: 1px solid transparent;

  .icon {
    fill: lighten($chips-blue-500, 30%);
  }

  a {
    color: lighten($chips-blue-500, 30%);
  }
}
@mixin chip-color-teal-unlinked {
  background-color: lighten($chips-teal-50, 1%);
  color: lighten($chips-teal-500, 30%);
  border: 1px solid transparent;

  .icon {
    fill: lighten($chips-teal-500, 30%);
  }

  a {
    color: lighten($chips-teal-500, 30%);
  }
}
@mixin chip-color-plum-unlinked {
  background-color: lighten($chips-plum-50, 1%);
  color: lighten($chips-plum-500, 30%);
  border: 1px solid transparent;

  .icon {
    fill: lighten($chips-plum-500, 30%);
  }

  a {
    color: lighten($chips-plum-500, 30%);
  }
}

//scrim
@mixin scrim-standard {
  background-color: $tundra-500;
  opacity: 0.72;
}

//surface or background
$workspace-surface: $structural-100;

//card or panel
@mixin workspace-panel {
  @include elevation-l1;
  background-color: $polar;
  border-radius: $radius-base;
}
@mixin shadow-box {
  @include elevation-l1;
}

@mixin box-basics {
  padding: $spacing-5x;
  background: $polar;
  margin-bottom: 10px;
  border-radius: $radius-base;
  margin-right: $spacing-base;
}

//other
$tab-background-resting-color: transparent;
$tab-background-hover-color: $tundra-100;
$tab-background-active-color: $tundra-100;
$tab-underline-resting-color: transparent;
$tab-underline-hover-color: transparent;
$tab-underline-active-color: $tundra-200;
$tab-underline-height: 3px;
$tab-text-resting-color: $text-medium-emphasis;
$tab-text-hover-color: $tundra-400;
$tab-text-active-color: $tundra-400;

//input fields
@mixin input-base {
  border-radius: $radius-base;
  border-style: solid;
  border-width: 1px;
  outline: none;
}

@mixin input-resting {
  border-color: $stroke-200; //border color
  color: $text-high-emphasis; //text color
  background-color: $polar;
}
@mixin input-hover {
  border-color: $stroke-300; //border color
  color: $text-high-emphasis; //text color
  background-color: $polar;
}
@mixin input-focus {
  border-color: $stroke-400; //border color
  color: $text-high-emphasis; //text color
  background-color: $polar;
}
@mixin input-active {
  border-color: $stroke-200; //border color
  color: $text-high-emphasis; //text color
  background-color: $polar;
}
@mixin input-disabled {
  border-color: $stroke-200; //border color
  color: $text-high-emphasis; //text color
  background-color: $structural-300;
}
@mixin input-focus-visible {
  border-color: $stroke-400; //border color
  color: $text-high-emphasis; //text color
  background-color: $polar;
}

@mixin input-text-standard {
  @include input-base;
  height: 40px;
  @include type-body-1;
  &:focus {
    @include input-focus;
  }
  &:hover {
    @include input-hover;
  }
  &:active {
    @include input-active;
  }
  &:disabled {
    @include input-disabled;
  }
  &:focus-visible {
    @include input-focus-visible;
  }
}

@mixin input-text-area-standard {
  @include input-base;
  resize: none;

  @include type-body-1;
  &:focus {
    @include input-focus;
  }
  &:hover {
    @include input-hover;
  }
  &:active {
    @include input-active;
  }
  &:disabled {
    @include input-disabled;
  }
  &:focus-visible {
    @include input-focus-visible;
  }
}

@mixin input-text-mini {
  @include input-base;
  height: 1.3rem;
  @include type-body-2;
  &:focus {
    @include input-focus;
  }
  &:hover {
    @include input-hover;
  }
  &:active {
    @include input-active;
  }
  &:disabled {
    @include input-disabled;
  }
  &:focus-visible {
    @include input-focus-visible;
  }
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2rem, $lineCount: 1) {
  display: block;
  display: -webkit-box;
  max-height: $text-size-s * math.div($lineHeight, 1rem) * ($lineCount + 1);
  font-size: $text-size-s;
  line-height: $lineHeight;
  -webkit-line-clamp: $lineCount;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
}

@mixin flex-box-center($gap: 0px) {
  display: flex;
  align-items: center;
  gap: $gap;
}
