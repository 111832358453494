@import '@codametrix/ui-common/src/styles/_variables';

.file-input-container {
  display: flex;
  flex-direction: row;
}

.list-spacer {
  width: 100%;
  height: $standardRelativePad;
  display: block;
}

.column-metadata-form {
  display: flex;
  flex-direction: column;
  margin-left: 20%;
  padding: $standardRelativePad;
}

.cmx-select-list {
  margin-left: $standardRelativePad;
}

.select-group {
  display: flex;
  flex-direction: row;
  margin: $standardRelativePad;
}
