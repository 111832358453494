/* Centralized AgGrid styles for the dashboard-ui & its children packages */
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

/* Scrollbar fix*/
.ag-body.ag-layout-normal {
  overflow-x: auto !important;
  overflow-y: auto !important;
  height: 100%;
}

.ag-theme-alpine {
  --ag-selected-row-background-color: rgb(255, 242, 239);
  --ag-checkbox-background-color: white;
  --ag-checkbox-checked-color: rgb(140, 180, 194);
  --ag-checkbox-unchecked-color: rgb(140, 180, 194);
}
.ag-theme-alpine .ag-header {
  background-color: #f7fdff;
  border-color: #d1f3ff;
  border-width: 1px 0px;
}
.ag-header-cell-text {
  font-size: 11.67px;
  line-height: 14px;
  font-weight: 600;
  font-family: Roboto;
}
.ag-cell {
  font-size: 11.67px;
  line-height: 14px;
  font-family: Roboto;
  display: flex;
  align-items: center;
  height: 30px;
}
.ag-theme-alpine .ag-header-row {
  color: #7498a6;
  font-weight: 600;
  min-height: 30px;
}
.ag-row-hover {
  cursor: pointer;
}
.ag-theme-alpine .ag-row {
  color: #203d48;
  border-bottom: 1px solid #d1f3ff;
  height: 30px;
  display: flex;
  align-items: center;
}

.ag-body-viewport {
  position: relative;
}

.ag-theme-alpine {
  font-family: 'Roboto', sans-serif;
}
.ag-theme-alpine .ag-root-wrapper {
  border: 0px solid;
}

.ag-theme-alpine .ag-ltr .ag-cell[col-id='queue'],
.ag-theme-alpine .ag-ltr .ag-cell[col-id='max_cases'],
.ag-theme-alpine .ag-ltr .ag-cell[col-id='assigned_cases'] {
  padding-right: 0;
  height: auto;
}

.ag-body-horizontal-scroll {
  display: none;
}

.ag-menu {
  background-color: white;
}

.ag-theme-alpine .ag-details-row {
  padding-top: 0;
  padding-bottom: 0;
}

.ag-theme-alpine
  .ag-root:not(.ag-context-menu-open)
  .ag-body-viewport:not(.ag-has-focus)
  .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
  box-shadow: none;
  background-color: none;
}

.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell {
  border: none;
  outline: none;
  height: 100%;
}

.ag-cell-focus {
  box-shadow: none;
  border: none;
  background: none;
  background-color: unset;
}

.ag-cell-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
