.link_uri {
  cursor: pointer;
}

.text_uri {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  white-space: nowrap;
}

.section_uri {
  display: flex;
}
