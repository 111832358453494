@import '@codametrix/ui-common/src/styles/_variables';

.attach-docs-modal {
  color: $text-high-emphasis !important;

  .attach-docs-title {
    margin: $spacing-5x 0;
  }

  .action-buttons {
    display: flex;
    gap: 15px;
  }

  .attach-docs-filters {
    display: flex;
    align-items: center;
    margin: $spacing-5x 0;

    .note-specialty-filter-sec {
      margin-left: $spacing-2x;
    }

    .note-type-filter-sec {
      margin-left: $spacing-2x;
    }

    .note-provider-filter-sec {
      margin-left: $spacing-2x;
    }

    .divider {
      width: 1px;
      height: 20px;
      background-color: $tundra-100;
    }

    .attach-docs-date-filter {
      width: 11vw;

      .chakra-input__right-element {
        margin-top: -5px;
      }
    }
  }
}
