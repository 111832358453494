@import '@codametrix/ui-common/src/styles/_variables';
.pageable-list-view {
  .heading-row {
    z-index: 100;
  }
  .control-container {
    font-size: $smallerText;
  }

  .paddles {
    padding: 0 $standardPad;
  }

  .pagination-controls {
    text-align: center;
    font-size: $smallText;
    .pagination-container {
      margin: 0 auto;
    }
  }

  .data-grid {
    &.table td,
    &.table th {
      padding: $standardRelativePad $smallRelativePad;
    }

    th {
      font-size: $smallText;
    }

    td {
      vertical-align: baseline;
      border-collapse: collapse;
      border: 1px dotted #dfdfdf;
      font-size: $smallText;
    }

    // headers set column widths
  }

  &.request-in-flight {
    .layout-inner-body {
      opacity: 0.8;
    }

    .showing {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .chips-container {
    .chips {
      display: inline-block;
      margin-right: 1rem;
    }

    .filter-chip {
      font-size: 0.7rem;
      display: inline-flex;
      flex-wrap: wrap;
      flex-direction: row;

      border: 1px solid gray;
      margin-left: 1px;
      margin-right: 1px;
    }

    .remove-filter-icon {
      color: gray;
    }

    .chip-section {
      text-align: center;
      min-width: 75px;
      padding-right: $smallRelativePad;
      padding-left: $smallRelativePad;
    }
    .chip {
      display: inline-flex;

      border: 1px solid $deemphasizedColor;

      margin-bottom: $smallRelativePad;
    }
    .chip-value,
    .chip-section {
      color: white;
      background: gray;
    }

    .chip-value {
      background: #efefef;
      color: gray;

      padding: 0.1rem 0.2rem;
    }
    .chip-close {
      margin: $smallRelativePad 0rem $smallRelativePad $smallRelativePad;

      //border-left: 1px solid gray;
      color: gray;
      background: #efefef;
    }
    .chip-close:hover {
      text-decoration: none;
      cursor: pointer;
      background-color: $ruleColor;
    }
  }

  .showing-column {
    font-size: $smallText;
    margin: auto;
  }

  .filters-column {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    margin-top: 5px;

    .filter-icon {
      padding-top: 0.2rem;
    }
  }

  .filters-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: $largerText;

    .filter-icon {
      padding-top: 0.2rem;
    }
  }

  .filter-dropdown {
    border: solid 1px $lineMedium;
    border-radius: 5px;
    padding: 1px 3px;
    overflow: visible;
    color: $Header;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.selected-dropdown {
      border-color: $buttonColorSelected;
    }

    &.filter-dropdown:hover {
      cursor: pointer;
      border-color: $buttonColorSelectedSemiTransparent;
    }
  }

  .filter-container {
    overflow: visible;
    margin-right: 15px;
    position: relative;
  }

  .cmx-card {
    overflow: visible;
  }

  .cmx-card.loading-card {
    overflow: hidden;
  }

  .filter-checklist {
    visibility: hidden;
    position: absolute;

    &.selected {
      visibility: visible;
    }
  }

  .filter-card {
    padding: 2px;
  }
  .date-filter-card {
    position: absolute;
  }
  .card-inner {
    background-color: white;
  }

  .dropdown-info {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 2px;
    margin-right: 2px;
    color: $bodyBlack;
    pointer-events: none;

    .info-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      margin-left: 2px;
      margin-right: 2px;
      margin-bottom: 2px;
      pointer-events: none;
    }
  }

  .pageable-gutter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .showing {
      margin-left: 15px;
      vertical-align: middle;
      line-height: 40px;
    }
    .page-size-select {
      margin-left: 16px;
    }
  }
}
