@import '@codametrix/ui-common/src/styles/_variables';

.loading-indicator {
  border-radius: $radius-base;
  margin: 0 auto;
  background-color: $loading-background-color;

  .loading-indicator-inner {
    padding: $spacing-3x;
  }
  .logo-loading-container {
    background: $almostLightBackground;
    border-radius: 0 0 $radius-base $radius-base;
  }
  .upper-loading-container {
    background: $loading-background-color;
    border-radius: $radius-base $radius-base 0 0;
  }

  .logo-loading-container,
  .upper-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  // SVG styles.']
  .loading-graphic {
    animation: 2s linear infinite svg-animation;
    max-width: 71px;
    width: 30px;
    margin: 17px;
  }

  // Circle styles.
  circle {
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke: $darkIconFill;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
  }
}
